main {
    width: 100%;
    background-color: rgb(247, 247, 247);
    position: relative;

    img {
        display: block;
        margin: auto;
        width: 1100px;
    }

    div {
        position: absolute;
        bottom: 10%;
        right: 28%;
        background-image: url('../../images/circle-icon.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 146px;
        width: 147px;
        display: flex;
        align-items: center;

        a {
            color: rgb(247, 247, 247);
            text-decoration: none;
            font-size: 22px;
            text-transform: uppercase;
            font-family: quicksand-bold-webfont;
            padding-left: 35px;
        }
    }

    @media screen and (max-width: 991.99px) {
        img {
            width: 300px;
            height: 300px;
        }

        div {
            height: 117px;
            width: 118px;

            a {
                padding-left: 20px;
                font-size: 20px;
            }
        }
    }
}
