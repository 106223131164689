footer {
    background-color: rgb(203, 51, 98);
    padding: 30px;

    ul {
        display: flex;
        justify-content: space-around;
        width: 20%;
        margin: 25px auto;

        li {
            list-style: none;

            a {
                color: white;
                font-size: 35px;
            }
        }
    }

    p {
        text-align: center;
        color: rgb(255, 255, 255);
        font: normal normal normal 15px/ 15px roboto-regular-webfont;
        text-align: center;
        margin: 0;
        margin-left: 40px;
    }

    @media screen and (max-width: 991.99px) {
        ul {
            width: 40%;
            margin: 25px auto;

            li {
                list-style: none;
                margin: 0 10px;

                a {
                    color: white;
                    font-size: 35px;
                }
            }
        }

        p {
            font-size: 12px;
        }
    }
}
