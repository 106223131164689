header.contact-us {
    width: 100%;

    h1 {
        font: normal small-caps normal 42px/ 30px lato-regular-webfont;
        color: rgb(255, 255, 255);
        background: rgb(200, 193, 13);
        margin: 0;
        text-align: center;
        width: 100%;
        padding: 28px 0;
    }
}
