/*-------------------------------------------Start-FONTS---------------------------------------------*/
@font-face {
  font-family: 'roboto-regular-webfont';
  src: url('../fonts/roboto-regular-webfont.eot');
  src: url('../fonts/roboto-regular-webfont.woff') format('woff'), url('../fonts/roboto-regular-webfont.ttf') format('truetype'), url('../fonts/roboto-regular-webfont.svg') format('svg');
}

@font-face {
  font-family: 'quicksand-bold-webfont';
  src: url('../fonts/quicksand-bold-webfont.eot');
  src: url('../fonts/quicksand-bold-webfont.woff') format('woff'), url('../fonts/quicksand-bold-webfont.ttf') format('truetype'), url('../fonts/quicksand-bold-webfont.svg') format('svg');
}

@font-face {
  font-family: 'magra-bold-webfont';
  src: url('../fonts/magra-bold-webfont.eot');
  src: url('../fonts/magra-bold-webfont.woff') format('woff'), url('../fonts/magra-bold-webfont.ttf') format('truetype'), url('../fonts/magra-bold-webfont.svg') format('svg');
}

@font-face {
  font-family: 'lato-regular-webfont';
  src: url('../fonts/lato-regular-webfont.eot');
  src: url('../fonts/lato-regular-webfont.woff') format('woff'), url('../fonts/lato-regular-webfont.ttf') format('truetype'), url('../fonts/lato-regular-webfont.svg') format('svg');
}

@font-face {
  font-family: 'lato-bold-webfont';
  src: url('../fonts/lato-bold-webfont.eot');
  src: url('../fonts/lato-bold-webfont.woff') format('woff'), url('../fonts/lato-bold-webfont.ttf') format('truetype'), url('../fonts/lato-bold-webfont.svg') format('svg');
}

/*----------------------------------------End-Fonts---------------------------------------*/
@import 'nav.scss';
@import 'footer.scss';
/**************************Start Home Style**********************/
@import './home/main.scss';
@import './home/newProduct.scss';
@import './home/icons.scss';
/**************************Start About Style**********************/
@import './about/header.scss';
@import './about/body.scss';
/**************************Start Product Style**********************/
@import './product/header.scss';
@import './product/body.scss';
/**************************Start blog Style**********************/
@import './blog/header.scss';
@import './blog/body.scss';
/**************************Start single post Style**********************/
@import './single-post/header.scss';
@import './single-post/body.scss';
/**************************Start contactus Style**********************/
@import './contactus/header.scss';
@import './contactus/body.scss';

body {
  margin: 0;
}

#root {
  overflow-x: hidden;
}
